(function (ui) {
	/* global ui */

	ui.register({
		widget: 'carouselstage',
		sel: '.carouselstage'
	});

	ui.register({
		widget: 'slider',
		sel: '.slider'
	});

	ui.register({
		widget: 'tabpanel',
		sel: '.tabpanel'
	});

	ui.register({
		widget: 'tiles',
		sel: '.tiles'
	});

	ui.register({
		widget: 'reloadparsys',
		sel: '.reloadparsys'
	});

	ui.register({
		widget: 'youtubevideo',
		sel: '.youtubevideo'
	});

	ui.$(() => {
		ui.init();
	});
}(ui));
