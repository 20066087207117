/* global ui */

(function ($) {
	$.widget('dmcp.tiles', {

		_create() {
			const _this = this;

			this.ACTIVE_CLASS = 'active';

			if (!ui.lib.isEditMode()) {
				this._initCarousel();
			}

			this.element.find('.tile').bind('click', function () {
				if (!$(this).hasClass(_this.ACTIVE_CLASS)) {
					const tileNumber = $(this).data('tile-count');
					_this._activateTile(tileNumber);
					_this._resetContent();

					if (ui.lib.isEditMode()) {
						_this._syncWithParsys(tileNumber);
					} else {
						_this._syncWithCarousel(tileNumber);
					}
				}
			});
		},

		_initCarousel() {
			this.$carousel = this.element.find('.carousel').slick({
				infinite: true,
				arrows: true,
				dots: false,
				slidesToShow: 1
			});

			this._syncWithTiles();
		},

		_getTile(number) {
			return this.element.find(`[data-tile-count="${number}"]`);
		},

		_activateTile(number) {
			this._resetTiles();
			this._turnTile(number);
		},

		_resetTiles() {
			this.element.find('.tile').removeClass(this.ACTIVE_CLASS);
		},

		_turnTile(number) {
			this._getTile(number).addClass(this.ACTIVE_CLASS);
		},

		_resetContent() {
			this.element.find('[data-content-count]').removeClass(this.ACTIVE_CLASS);
		},

		_syncWithCarousel(tileNumber) {
			this.$carousel.slick('slickGoTo', tileNumber - 1);
		},

		_syncWithTiles() {
			const _this = this;
			this.$carousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
				_this._activateTile(nextSlide + 1);
			});
		},

		_syncWithParsys(tileNumber) {
			this.element.find('[data-content-count]').hide();
			this.element.find(`[data-content-count="${tileNumber}"]`).show();
		}
	});
}(ui.$));
