/* global ui, OnetrustActiveGroups,  OneTrust */
(function ($) {
	$.widget('dmcp.youtubevideo', {

		options: {
			youtubeId: null,
			consentCategory: 'YOU01'
		},

		_create() {
			ui.merge('dmcp.youtubevideo', this);

			if (!this.options || !this.options.youtubeId || !this.options.consentCategory) {
				ui.log('YouTube Video: ', 'Could not load configuration.');
			} else {
				this._replaceYoutubeIframe(this);
			}
		},

		_replaceYoutubeIframe() {
			const _this = this;
			const consentNoteDiv = _this.element.parent().find('[name="consent-note-placeholder"]');
			// Check required cookie type.
			let consentOk = true;
			// If 'OneTrust' is not available, not included (or we are on an author instance): ignore consent.

			// There exists a race condition between youtubevideo.js and oneTrust (otSDKStub.js).
			// Sometimes the object "OnetrustActiveGroups" is available too late
			const oneTrustIntervall = window.setInterval(() => {
				if (_this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
					window.clearInterval(oneTrustIntervall);

					if (_this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
						const categories = _this.options.consentCategory.split(',');
						categories.forEach((category) => {
							if (OnetrustActiveGroups.indexOf(category.trim()) === -1) {
								consentOk = false;
							}
						});
					}
					if (consentOk) {
						const iframeSrc = `https://www.youtube.com/embed/${_this.options.youtubeId
						}?showinfo=0&amp;amp;rel=0&amp;amp;modestbranding=1&amp;amp;enablejsapi=1`;
						consentNoteDiv.remove();
						/* eslint-disable no-mixed-spaces-and-tabs, max-len */
						_this.element.append(`<div class="embed-responsive"><iframe class="embed-responsive-item" src="${iframeSrc}"`
											 + ` allow="autoplay" allowfullscreen="allowfullscreen" title="${_this.options.youtubeId}"></iframe></div>`);
						_this.element.removeClass('hidden');
						/* eslint-enable no-mixed-spaces-and-tabs, max-len */
					} else {
						// Show button and text to accept required cookies.
						consentNoteDiv.find('button').click({ component: _this.element, conf: _this.options }, (e) => {
							const analytics = OneTrust.GetDomainData().Groups
								.filter((id) => id.OptanonGroupId === _this.options.consentCategory);
							let purpose;
							if (analytics && analytics.length > 0 && analytics[0]) {
								purpose = analytics[0].PurposeId;
							}
							OneTrust.setConsentProfile({ purposes: [{ Id: purpose, TransactionType: 'CONFIRMED' }] });
							OneTrust.Init();
							_this._replaceYoutubeIframe(e.data.component);
						});
					}
				}
			}, 50);

			window.setTimeout(() => {
				window.clearInterval(oneTrustIntervall);
			}, 3000);
		}
	});
}(ui.$));
