/* global ui */

(function ($) {
	$.widget('dmcp.carouselstage', {

		_create() {
			this.element.find('.carousel').slick({
				infinite: true,
				arrows: true,
				dots: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 3,
				responsive: [
					{
						breakpoint: 786,
						settings: {
							slidesToShow: 1
						}
					},
					{
						breakpoint: 599,
						settings: {
							slidesToShow: 1,
							autoplay: true,
							speed: 1000,
							autoplaySpeed: 4000
						}
					}
				]
			});
		}
	});
}(ui.$));
