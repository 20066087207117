/* global ui */

(function ($) {
	$.widget('dmcp.reloadparsys', {

		_create() {
			const _this = this;

			this._on({
				'click .loadMoreBtn': function (e) {
					ui.stop(e);
					_this._showLoader();
					const url = $(e.currentTarget).data('url');

					$.ajax({
						url,
						dataType: 'html',
						async: true,
						success(data) {
							_this._appendResults(data);
						},
						error(jqXHR, textStatus) {
							ui.log('autocomplete xhr error', textStatus, ui.config.xhrErrorColor);
							_this._hideLoader();
						}
					});
				}
			});
		},

		_appendResults(content) {
			this.element.find('.loadMoreContainer').replaceWith(content);
			ui.init();
		},

		_getLoader() {
			return this.element.find('.loader');
		},

		_hideLoader() {
			this._getLoader().addClass('hidden');
		},

		_showLoader() {
			this._getLoader().removeClass('hidden');
		}
	});
}(ui.$));
