/* global ui */

(function ($) {
	$.widget('dmcp.tabpanel', {

		_create() {
			const _this = this;

			this.ACTIVE_CLASS = 'active';

			if (!ui.lib.isEditMode()) {
				this._initCarousel();
			}

			this.element.find('.tab').bind('click', function () {
				if ($(this).hasClass(_this.ACTIVE_CLASS)) {
					// do nothing
				} else {
					const tabNumber = $(this).data('tab-count');
					_this._handleTabs(tabNumber);
					_this._resetContent();

					if (ui.lib.isEditMode()) {
						_this._syncWithParsys(tabNumber);
					} else {
						_this._syncWithCarousel(tabNumber);
					}
				}
			});
		},

		_initCarousel() {
			this.$carousel = this.element.find('.carousel').slick({
				infinite: false,
				arrows: true,
				dots: false,
				slidesToShow: 1,
				prevArrow: this.element.find('.prev'),
				nextArrow: this.element.find('.next')
			});

			this._syncWithTabs();
		},

		_getTab(number) {
			return this.element.find(`[data-tab-count="${number}"]`);
		},

		_handleTabs(number) {
			this._resetTabs();
			this._activateTab(number);
		},

		_resetTabs() {
			this.element.find('.tab').removeClass(this.ACTIVE_CLASS);
		},

		_activateTab(number) {
			this._getTab(number).addClass(this.ACTIVE_CLASS);
		},

		_resetContent() {
			this.element.find('[data-content-count]').removeClass(this.ACTIVE_CLASS);
		},

		_syncWithCarousel(tabNumber) {
			this.$carousel.slick('slickGoTo', tabNumber - 1);
		},

		_syncWithTabs() {
			const _this = this;
			this.$carousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
				_this._handleTabs(nextSlide + 1);
			});
		},

		_syncWithParsys(tabNumber) {
			this.element.find('[data-content-count]').hide();
			this.element.find(`[data-content-count="${tabNumber}"]`).show();

			this._showOnlyTheParsysFromSelectedTab(tabNumber);
		},

		_showOnlyTheParsysFromSelectedTab(tabNumber) {
			// Hide all parsys
			$.each(this.element.find('[data-content-count]'), function () {
				const parsysPath = $(this).data('parsys-path');
				// TODO IPNP-2679: update syntax for magnolia author
				/*
				 const parsysComp = CQ.WCM.getEditable(parsysPath);
				 if (parsysComp) {
				 parsysComp.hide();
				 } */
			});

			// Show current parsys
			const currentParsysPath = this.element.find(`[data-content-count="${tabNumber}"]`).data('parsys-path');
			// TODO IPNP-2679: update syntax for magnolia author
			/*	const currentParsysComp = CQ.WCM.getEditable(currentParsysPath);
			 if (currentParsysComp) {
			 currentParsysComp.show();
			 } */
		}
	});
}(ui.$));
