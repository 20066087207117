// This is the main JavaScript file of all client DMCP specific scripts.

// This includes all component specific JS files needed by the client DMCP.

import 'jquery-ui';

// jQuery Widget Factory
import '../../../../base/templates/client-libs/js/ui.legacy/ui.bottom';
import '../../../../base/templates/client-libs/js/ui.legacy/ui.lib';

import 'slick-slider';

// DMCP Components
import '../../components/stage/carousel-stage/carousel-stage';
import '../../components/slider/slider';
import '../../components/tab-panel/tab-panel';
import '../../components/tiles/tiles';
import '../../components/youtube-video/youtube-video';
import '../../components/parsys/reload-parsys/reload-parsys';

// initialize jquery widgets
import './ui.legacy/ui.index';
