/* global ui */

(function ($) {
	$.widget('dmcp.slider', {

		_create() {
			this.element.find('.carousel').slick({
				infinite: true,
				arrows: true,
				dots: false,
				slidesToShow: 1
			});
		}
	});
}(ui.$));
